import { useCallback, useEffect, useRef, useState, useMemo, Key } from "react"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { cn, createLocker, formatDate } from "@/lib/utils"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Link } from "react-router-dom"
import { TablePaginationProvider } from "./contexts/TablePaginationProvider"
import { useTablePagination } from "./hooks/useTablePagination"
import { TableProps } from "@/components/table/types/table.types"
import { BaseColumn, BaseItem, LinkItem } from "@/components/table/types/common.types"
import { ColumnHeader } from "@/components/table/components/ColumnHeader"
import { RowActions } from "./components/RowActions"
import { ButtonAction } from "./components/ButtonAction"
import { Card } from "@/components/ui/card"

export function TableFactory<TItem extends BaseItem, TColumn extends BaseColumn>({ tableId, useColumns, hasActions }: TableProps<TItem, TColumn>) {
	return (
		<TablePaginationProvider>
			<TableContent tableId={tableId} useColumns={useColumns} hasActions={hasActions} />
		</TablePaginationProvider>
	)
}

function TableContent<TItem extends BaseItem, TColumn extends BaseColumn>({ tableId, useColumns, hasActions = true }: TableProps<TItem, TColumn>) {
	// State management
	const [items, setItems] = useState<TItem[]>([])
	const [selectedItems, setSelectedItems] = useState<number[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isInitialized, setIsInitialized] = useState(false)

	// Refs
	const lockerRef = useRef(createLocker())
	const previousParamsRef = useRef("")

	// Custom hooks
	const { data: columnsData, error: columnsError, isLoading: isLoadingColumns } = useColumns()
	const { sort, setSort, cursor, setCursor, next, setNext, prev, setPrev, limit, setLimit, filters } = useTablePagination()

	const config = {
		cursorParam: "cursor",
		limitParam: "limit",
		sortParam: "sort",
		prevParam: "prev",
		nextParam: "next",
		filterParam: "filter",
	}
	const currentParams = useMemo(
		() => ({
			sort,
			limit,
			filters,
			cursor,
		}),
		[sort, limit, filters, cursor],
	)

	const fetchData = useCallback(
		async ({ prevPage = false, nextPage = false } = {}) => {
			if (isLoading) {
				console.log("Skipping fetch - Already loading")
				return
			}

			const unlock = await lockerRef.current()

			try {
				setIsLoading(true)

				const params = new URLSearchParams({
					[config.prevParam]: prevPage.toString(),
					[config.nextParam]: nextPage.toString(),
					[config.limitParam]: currentParams.limit.toString(),
					[config.sortParam]: currentParams.sort,
				})

				params.append(config.cursorParam, cursor.toString())

				if (currentParams.filters.length > 0) {
					currentParams.filters.forEach(filter => {
						params.append(config.filterParam, filter.filter)
					})
				}

				const paramsString = params.toString()
				// console.log("Final request params:", paramsString)

				if (!prevPage && !nextPage && paramsString === previousParamsRef.current) {
					// console.log("Skipping duplicate fetch")
					return
				}
				previousParamsRef.current = paramsString
				// console.log("Final URL with sort:", params.toString())

				const response = await fetch(`/api/${tableId}?${params}`)
				if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
				const data = await response.json()
				// console.log("Received response:", data)

				setItems(data.items || [])
				setCursor(data.cursor)
				setPrev(data.has_prev)
				setNext(data.has_next)

				if (!isInitialized) setIsInitialized(true)
			} catch (error) {
				console.error("Failed to fetch data:", error)
				setItems([])
				setPrev(false)
				setNext(false)
			} finally {
				setIsLoading(false)
				unlock()
			}
		},
		[currentParams, cursor, isLoading],
	)

	useEffect(() => {
		// console.log("Init effect triggered, tableId:", tableId)
		setSort("id,asc")
		setIsInitialized(false)
		setCursor(0)
		setNext(false)
		setPrev(false)
		return () => {
			// Cleanup au démontage
			setIsInitialized(false)
		}
	}, [tableId])

	const handlePrevClick = useCallback(() => {
		if (!isLoading && prev && cursor > 0) {
			fetchData({ prevPage: true })
			setSelectedItems([])
		}
	}, [fetchData, isLoading, prev, cursor])

	const handleNextClick = useCallback(() => {
		if (!isLoading && next && cursor > 0) {
			fetchData({ nextPage: true })
			setSelectedItems([])
		}
	}, [fetchData, isLoading, next, cursor])

	const handleRowsPerPageChange = useCallback(
		(value: string) => {
			const newLimit = Number(value)
			setLimit(newLimit)
			setSelectedItems([])
		},
		[setLimit],
	)

	const toggleSelectItem = useCallback((id: number) => {
		setSelectedItems(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]))
	}, [])

	const toggleSelectAll = useCallback(() => {
		setSelectedItems(prev => (prev.length === items.length ? [] : items.map(item => item.id)))
	}, [items])

	useEffect(() => {
		if (!isInitialized && !isLoading) {
			fetchData()
		}
	}, [isInitialized, isLoading])

	useEffect(() => {
		if (!isInitialized) return

		const timeoutId = setTimeout(() => {
			fetchData() // Sans options = pas de pagination
		}, 400)

		return () => clearTimeout(timeoutId)
	}, [sort, filters, limit])

	const renderCell = (item: TItem, column: TColumn) => {
		const value = item[column.id]

		if (value === undefined || value === null) {
			return "-"
		}

		switch (column.type) {
			case "link": {
				const linkItem = value as LinkItem
				if (!linkItem?.url || !linkItem?.text) {
					return "-"
				}
				return (
					<Link to={linkItem.url} target="_blank">
						{linkItem.text}
					</Link>
				)
			}
			case "date":
				try {
					return formatDate(Number(value))
				} catch {
					return "-"
				}
			case "enumeration": {
				const enumerationItem = column.values?.find(v => v.id === value)
				return enumerationItem?.label || "N/A"
			}
			default:
				return String(value)
		}
	}

	const showPagination = useMemo(() => {
		return items.length >= limit
	}, [items.length, limit])

	// Loading and error states
	if (isLoadingColumns) return <div>Loading...</div>
	if (columnsError) return <div>Error loading data</div>
	if (!columnsData?.columns?.length) return <div>No columns available</div>

	const columns = columnsData.columns
	const allSelected = items.length > 0 && selectedItems.length === items.length
	const pagination = ["10", "25", "50", "100"]

	return (
		<Card className="w-full overflow-auto">
			{selectedItems.length > 0 ? (
				<div className="p-2">
					<Alert variant="default" className="flex justify-between items-center p-2">
						<div className="flex items-center gap-2">
							<AlertTitle>
								<Badge variant="secondary" className="h-6 w-6 flex items-center justify-center">
									{selectedItems.length}
								</Badge>
							</AlertTitle>
							<AlertDescription className="text-sm">{`Selected ${selectedItems.length} item${selectedItems.length === 1 ? "" : "s"}`}</AlertDescription>
						</div>
						<div className="flex items-center">
							<ButtonAction tableId={tableId} selectedItems={selectedItems} setSelectedItems={setSelectedItems} refresh={fetchData}>
								Actions
							</ButtonAction>
						</div>
					</Alert>
				</div>
			) : null}

			<Table>
				<TableHeader>
					<TableRow className="items-start">
						<TableHead className="w-fit align-top">
							<div className="flex h-8 items-start">
								<Checkbox checked={allSelected} onCheckedChange={toggleSelectAll} aria-label="Select all" className="mt-3 ml-1" />
							</div>
						</TableHead>
						{columns.map((column: { id: Key | null | undefined; label: string }) => (
							<TableHead key={column.id} className={cn("align-top", column.id === "action" ? "text-right" : "")}>
								<ColumnHeader
									tableId={tableId}
									id={column?.id?.toString() as string}
									label={column.label}
									setIsInitialized={setIsInitialized}
									className={column.id === "action" ? "text-right relative" : "relative"}
									hasActions={hasActions}
								/>
							</TableHead>
						))}
					</TableRow>
				</TableHeader>

				<TableBody>
					{items.map(item => (
						<TableRow key={item.id}>
							<TableCell className="w-fit">
								<div className="flex h-8 items-center">
									<Checkbox
										checked={selectedItems.includes(item.id)}
										onCheckedChange={() => toggleSelectItem(item.id)}
										aria-label={`Select item ${item.id}`}
										className="mt-1 ml-1"
									/>
								</div>
							</TableCell>
							{columns.map((column: TColumn) => (
								<TableCell key={column.id}>{renderCell(item, column)}</TableCell>
							))}
							{hasActions ? (
								<TableCell>
									<RowActions row={item} selectedItems={selectedItems} setSelectedItems={setSelectedItems} refresh={fetchData} />
								</TableCell>
							) : null}
						</TableRow>
					))}
				</TableBody>

				{!isLoading && showPagination ? (
					<TableCaption className="mt-1">
						<div className="flex items-center justify-between pl-2 mb-2">
							<div className="flex items-center space-x-2">
								<Select value={limit.toString()} onValueChange={handleRowsPerPageChange} disabled={isLoading}>
									<SelectTrigger className="w-[80px]">
										<SelectValue />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{pagination.map(size => (
												<SelectItem key={size} value={size}>
													{size}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
								<span className="text-sm text-muted-foreground">rows</span>
							</div>

							<div className="flex space-x-2 mr-2">
								<Button variant="ghost" disabled={!prev || isLoading} onClick={handlePrevClick}>
									Previous
								</Button>
								<Button variant="ghost" disabled={!next || isLoading} onClick={handleNextClick}>
									Next
								</Button>
							</div>
						</div>
					</TableCaption>
				) : null}
			</Table>
		</Card>
	)
}
