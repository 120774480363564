import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import LoginPage from "../pages/PageLogin"
import ResetForm from "../pages/PageResetPassword"
import NewPassword from "../pages/NewPassword/NewPassword"
import MainLayout from "../layouts/mainLayout"
import withRoutes from "./Routes"
import { Toaster } from "@/components/ui/toaster"
import { ProtectedRoute } from "@/routes/ProtectedRoute"
import { lazy, Suspense } from "react"
import { RoutesType } from "./routesType"

// Import lazy-loaded components
const Page404 = lazy(() => import("../pages/NotFound/PageNotFound"))

const router = ({ routes }: RoutesType) => {
	// console.log("Routes:", routes) // For debugging purposes

	const router = createBrowserRouter([
		{
			path: "/",
			element: (
				<ProtectedRoute>
					<MainLayout>
						<Suspense fallback={<div>Loading...</div>}>
							<Outlet />
							<Toaster />
						</Suspense>
					</MainLayout>
				</ProtectedRoute>
			),
			children: routes,
			// shouldRevalidate: ({ actionStatus, defaultShouldRevalidate }) => {
			// 	// Si on a une erreur d'action (4xx/5xx)
			// 	if (actionStatus != null && actionStatus >= 400) {
			// 		// On peut personnaliser la logique de revalidation ici
			// 		// Par défaut, on ne revalide pas en cas d'erreur
			// 		return false
			// 	}
			// 	return defaultShouldRevalidate
			// },
		},
		{
			path: "login",
			element: <LoginPage />,
		},
		{
			path: "reset_password",
			element: <ResetForm />,
		},
		{
			path: "new_password",
			element: <NewPassword />,
		},
		{
			path: "404",
			element: <Page404 />,
		},
		{
			path: "*",
			element: <Navigate to="/404" replace />,
		},
		{
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			future: {
				v7_fetcherPersist: true,
				v7_normalizeFormMethod: true,
				v7_partialHydration: true,
				v7_relativeSplatPath: true,
				v7_skipActionErrorRevalidation: true,
			},
		},
	])

	return <RouterProvider router={router} future={{ v7_startTransition: true }} />
}

export const Router = withRoutes(router)
